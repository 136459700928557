
import Dashboard from "views/Dashboard.js";
import Slider from "views/Slider.js";
import SliderProfile from "views/SliderProfile";
import SliderOurFounder from "views/SliderOurFounder";
import SliderWhatTheySay from "views/SliderWhatTheySay";
import SliderPublications from "views/SliderPublications";
import SliderAwards from "views/SliderAwards";
import SliderLawyers from "views/SliderLawyers";
import SliderAssociates from "views/SliderAssociates";
import SliderDepartments from "views/SliderDepartments";
import SliderAreasOfPractice from "views/SliderAreasOfPractice";
import SliderNews from "views/SliderNews";
import SliderAssociatedOffices from "views/SliderAssociatedOffices";
import SliderContactUs from "views/SliderContactUs";
import Disclaimer from "views/Disclaimer";
import SliderDisclaimer from "views/SliderDisclaimer";
import Footer from "views/Footer";
import Adduser from "views/Adduser";
import Navbar from "views/Navbar";
import AboutSection from "views/AboutSection";
import News from "views/News";
import Achievements from "views/Achievements";
import Profile from "views/Profile";
import OurFounder from "views/OurFounder";
import WhatTheySay from "views/WhatTheySay";
import Publications from "views/Publications";
import AwardsAndRankings from "views/AwardsAndRankings";
import Lawyers from "views/Lawyers";
import Departments from "views/Departments";
import Associates from "views/Associates";
import AreasofPractice from "views/AreasofPractice";
import AssociatedOffices from "views/AssociatedOffices";
import ContactUs from "views/ContactUs";
import AddNewPage from "views/AddNewPage";
import AnotherPages from "views/AnotherPages";
import SocialMedia from "views/SocialMedia";




var routes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "nc-icon nc-bank",
    component: (
    <Dashboard />
    ),
    layout: "/admin",
  },
  {
    path: "/Navbar",
    name: "Navbar ",
    icon: "nc-icon nc-align-left-2",
    component: <Navbar />,
    layout: "/admin",
  },
  {
    path: "/newpage",
    name: "New Page",
    icon: "nc-icon nc-simple-add",
    component: <AddNewPage />,
    layout: "/admin",
  },
  {
    path: "/anotherpages",
    name: "Another Pages",
    icon: "nc-icon nc-ruler-pencil",
    component: <AnotherPages />,
    layout: "/admin",
  },
  {
    path: "/Slider-home",
    name: "Home Slider",
    icon: "nc-icon nc-button-play",
    component: <Slider />,
    layout: "/admin",

  },
  {
    path: "/AboutSection",
    name: "About Section",
    icon: "nc-icon nc-single-copy-04",
    component: <AboutSection />,
    layout: "/admin",

  },
  {
    path: "/News",
    name: "News",
    icon: "nc-icon nc-map-big",
    component: <News />,
    layout: "/admin",

  },
  {
    path: "/Slider-News",
    name: "News Slider",
    icon: "nc-icon nc-button-play",
    component: <SliderNews />,
    layout: "/admin",

  },
  {
    path: "/Achievements",
    name: "Achievements",
    icon: "nc-icon nc-spaceship",
    component: <Achievements />,
    layout: "/admin",

  },
  {
    path: "/Profile",
    name: "Profile",
    icon: "nc-icon nc-single-02",
    component: <Profile />,
    layout: "/admin",

  },
  {
    path: "/profile-Slider",
    name: "Profile Slider ",
    icon: "nc-icon nc-button-play",
    component: <SliderProfile />,
    layout: "/admin",

  },
  {
    path: "/ourfounder",
    name: "Our Founder",
    icon: "nc-icon nc-badge",
    component: <OurFounder />,
    layout: "/admin",

  },
  {
    path: "/our-Founder-slider",
    name: "Our Founder Slider",
    icon: "nc-icon nc-button-play",
    component: <SliderOurFounder />,
    layout: "/admin",

  },
  {
    path: "/what-they-say",
    name: "What They Say",
    icon: "nc-icon nc-chat-33",
    component: <WhatTheySay />,
    layout: "/admin",

  },
  {
    path: "/Pro-Bono-Slider",
    name: "Pro Bono Slider",
    icon: "nc-icon nc-button-play",
    component: <SliderWhatTheySay />,
    layout: "/admin",

  },
  {
    path: "/publications",
    name: "Publications",
    icon: "nc-icon nc-send",
    component: <Publications />,
    layout: "/admin",

  },
  {
    path: "/Slider-publications",
    name: "Publications Slider",
    icon: "nc-icon nc-button-play",
    component: <SliderPublications />,
    layout: "/admin",

  },
  {
    path: "/awardsandrankings",
    name: "Awards and Rankings",
    icon: "nc-icon nc-trophy",
    component: <AwardsAndRankings />,
    layout: "/admin",

  },
  {
    path: "/Awards-Slider",
    name: "Awards Slider",
    icon: "nc-icon nc-button-play",
    component: <SliderAwards />,
    layout: "/admin",

  },
  {
    path: "/Partners",
    name: "Partners",
    icon: "nc-icon nc-vector",
    component: <Lawyers />,
    layout: "/admin",

  },
  {
    path: "/Slider-Partners",
    name: "Partners Slider",
    icon: "nc-icon nc-button-play",
    component: <SliderLawyers />,
    layout: "/admin",

  },
  {
    path: "/Of-counsel",
    name: "Of Counsel",
    icon: "nc-icon nc-vector",
    component: <Departments />,
    layout: "/admin",

  },
  {
    path: "/Slider-Of-counsel",
    name: "Of Counsel Slider",
    icon: "nc-icon nc-button-play",
    component: <SliderDepartments />,
    layout: "/admin",

  },
  {
    path: "/associates",
    name: "Associates",
    icon: "nc-icon nc-vector",
    component: <Associates />,
    layout: "/admin",

  },
  {
    path: "/Slider-Associates",
    name: "Associates Slider",
    icon: "nc-icon nc-button-play",
    component: <SliderAssociates />,
    layout: "/admin",

  },
  {
    path: "/AreasofPractice",
    name: "Areas of Practice",
    icon: "nc-icon nc-layout-11",
    component: <AreasofPractice />,
    layout: "/admin",

  },
  {
    path: "/Areas-Of-Practice-Slider",
    name: "Areas Practice Slider",
    icon: "nc-icon nc-button-play",
    component: <SliderAreasOfPractice />,
    layout: "/admin",

  },
  {
    path: "/AssociatedOffices",
    name: "Associated Offices",
    icon: "nc-icon nc-globe-2",
    component: <AssociatedOffices />,
    layout: "/admin",

  },
  {
    path: "/Associated-Offices-Slider",
    name: "Associated Slider",
    icon: "nc-icon nc-button-play",
    component: <SliderAssociatedOffices />,
    layout: "/admin",

  },
  {
    path: "/contactus",
    name: "Contact Us",
    icon: "nc-icon nc-email-85",
    component: <ContactUs />,
    layout: "/admin",

  },
  {
    path: "/Contact-Us-Slider",
    name: "Contact Us Slider",
    icon: "nc-icon nc-button-play",
    component: <SliderContactUs />,
    layout: "/admin",

  },
  {
    path: "/disclaimer",
    name: "Disclaimer",
    icon: "nc-icon nc-map-big",
    component: <Disclaimer />,
    layout: "/admin",

  },  
  {
    path: "/Disclaimer-Slider",
    name: "Disclaimer Slider",
    icon: "nc-icon nc-button-play",
    component: <SliderDisclaimer />,
    layout: "/admin",

  },
  {
    path: "/social-media",
    name: "Social Media",
    icon: "nc-icon nc-world-2",
    component: <SocialMedia />,
    layout: "/admin",

  },
  {
    path: "/addUser",
    name: "Add user",
    icon: "nc-icon nc-simple-add",
    component: <Adduser />,
    layout: "/admin",

  },

];

// Check the user's role and filter out restricted routes
const userRole = localStorage.getItem("userRole");
if (userRole === "standard") {
  routes = routes.filter(
    (route) =>
      route.path !== "/user-management" && route.path !== "/UserPage"
  );
}

export default routes;
