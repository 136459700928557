// Terms.js (Front-End)

import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Row,
  Col,
  Table,
  Form,
  FormGroup,
  Input,
  Button, Modal, ModalHeader, ModalBody, ModalFooter
} from "reactstrap";

function Footer() {
  const [terms, setTerms] = useState([]);

  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const [showUpdateImage, setShowUpdateFormImage] = useState(false);

  const [selectedTermId, setSelectedTermId] = useState(null);
  const [updateTerm, setUpdateTerm] = useState({
    title: "",
    description: "",
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [termCount, setTermCount] = useState(0);
  const [successMessage, setSuccessMessage] = useState("");
  const [updateErrorMessage, setUpdateErrorMessage] = useState("");
  const [newImage, setNewImage] = useState(null);
  const [images, setImages] = useState([]);
  const [imageCount, setimageCount] = useState(0);
  const [addError, setAddError] = useState("");
  const [ImageError, setImageError] = useState("");
  const [addSuccessMessage, setAddSuccessMessage] = useState("");
  const [ImageErrorUpdate, setImageErrorUpdate] = useState("");
  const [updateSuccessMessage, setUpdateSuccessMessage] = useState("");
  const [editingimage, setEditingimage] = useState(null);
  const [updateError, setUpdateError] = useState("");
  const [deleteimageId, setDeleteimageId] = useState(null);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [formInitialDisplay, setFormInitialDisplay] = useState(false);
  const [editingIcon, setEditingIcon] = useState(null);



  const fetchImages = async () => {
    try {
      const response = await axios.get("https://demo1.we-demo.xyz/ProjectsImages/list");
      if (response.data && response.data.success) {
        setImages(response.data.data); // Set images in state
        setimageCount(response.data.data.length);

      } else {
        console.error("API request was not successful:", response.data.errors);
      }
    } catch (error) {
      console.error("Error fetching images:", error);
    }
  };

  // useEffect hook to fetch images when the component mounts
  useEffect(() => {
    fetchImages();
  }, []);




  
  const isValidImageExtension = (fileName) => {
    const validExtensions = ['.webp', '.gif', '.png', '.jpg', '.jpeg'];
    const fileExtension = fileName.slice(((fileName.lastIndexOf(".") - 1) >>> 0) + 2).toLowerCase();
    return validExtensions.includes(`.${fileExtension}`);
  };
  const addImage = async (e) => {
    e.preventDefault();
 
    const imageInput = document.getElementById("newimageimage");
  

    const imageFile = imageInput.files[0];
  
    if ( !imageFile) {
      setAddError("All fields are required.");
      setTimeout(() => { setAddError(""); }, 5000);
      return;
    }
  
    if (!isValidImageExtension(imageFile.name)) {
      setImageError("Invalid file extension. Please upload .WEBP, .GIF, .PNG, .JPG, or .JPEG");
      setTimeout(() => { setImageError(""); }, 5000);
      return;
    }
  
    const formData = new FormData();

    formData.append("path", imageFile);
  
    try {
      const response = await axios.post("https://demo1.we-demo.xyz/ProjectsImages/add", formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
  
      if (response.data.success) {
        fetchImages();
        setAddSuccessMessage("Social Media added successfully");
        setTimeout(() => { setAddSuccessMessage(""); }, 5000);
  
      
        imageInput.value = "";
  
      } else {
        setAddError("Failed to add image: " + response.data.errors);
      }
    } catch (error) {
      console.error("Error adding image:", error);
      setAddError("Error occurred whileadding the image.");
      setTimeout(() => {
        setAddError("");
      }, 5000);
  }
  };



  
  


  const updateimage = async (id) => {
    // Validation checks

  
    // Creating a FormData object to handle the data being sent in the PUT request
    const formData = new FormData();

  
    // Adding the new image to the formData only if it exists and is valid
    if (newImage) {
      if (!isValidImageExtension(newImage.name)) {
        setImageErrorUpdate("Invalid file extension. Allowed: .WEBP, .GIF, .PNG, .JPG, .JPEG");
        setTimeout(() => {
          setImageErrorUpdate("");
        }, 5000);
        return;
      }
      formData.append("path", newImage);
    }
  
    // Making an asynchronous PUT request to update the image
    try {
      const response = await axios.put(`https://demo1.we-demo.xyz/ProjectsImages/update/${id}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
  
      // Handling the response after the image is successfully updated
      if (response.data.success) {
        fetchImages(); // Refresh the list of images
        setUpdateSuccessMessage("Social Media updated successfully");
        setTimeout(() => {
          setUpdateSuccessMessage("");
        }, 5000);
  
        // Reset the update form fields but keep the update section visible
        setEditingimage({ ...editingimage, path: "" });
        setNewImage(null);
      } else {
        setUpdateError("Failed to update image: " + response.data.errors);
      }
    } catch (error) {
      setUpdateError("Error occurred while updating the image.");
    }
  };
  
  const handleUpdate1 = (icon) => {
    setEditingimage(icon);
    setShowUpdateFormImage(true); // Show the update form
  };
  const closeUpdateForm = () => {
    setEditingimage(null);
    setShowUpdateFormImage(false);
  };
  


  const deleteimage = (id) => {
    setDeleteimageId(id);
    setShowDeletePopup(true);
  };

  

  const performDelete = async () => {
    try {
      const response = await axios.delete(`https://demo1.we-demo.xyz/ProjectsImages/delete/${deleteimageId}`);
      if (response.data.success) {
        setImages(images.filter((image) => image.id !== deleteimageId));
        setShowDeletePopup(false);
      } else {
        console.error("Failed to delete image");
      }
    } catch (error) {
      console.error("Error deleting image:", error);
    }
  };
  
  
    useEffect(() => {
      fetchImages();
    }, []);

   
  


    useEffect(() => {
        if (editingimage && !formInitialDisplay) {
          setFormInitialDisplay(true); // Indicate that the form is being displayed initially
          const nameInputField = document.getElementById('edit-image-name');
          if (nameInputField) {
            nameInputField.focus();
          }
        } else if (!editingimage && formInitialDisplay) {
          setFormInitialDisplay(false); // Reset when the form is closed
        }
      }, [editingimage]);


  

  const fetchData = async () => {
    try {
      const response = await axios.get("https://demo1.we-demo.xyz/partnerstitle/list");
      if (response.data && response.data.success) {
        setTerms(response.data.data); // Wrapping the object in an array
        setTermCount(1); // Since it'sa single object
      } else {
        console.error("Unexpected response format:", response.data);
        // Handle this situation appropriately
      }
    } catch (error) {
      console.error(`Error getting data: ${error}`);
      setLoading(false);
      setError("Error fetching data. Please try again later.");
    }
  };
  
  



  const handleUpdate = (term) => {
    setSelectedTermId(term.id);
    setUpdateTerm({
      title: term.title,
      paragraph: term.paragraph,
    });
    setShowUpdateForm(!showUpdateForm);
    };

    const handleTermUpdate = async (e) => {
      e.preventDefault();
    
      // Validation: Check if title and description are filled
      if (!updateTerm.title.trim() || !updateTerm.paragraph.trim()) {
        setUpdateErrorMessage("Title and paragraph are required.");
        setTimeout(() => {
          setUpdateErrorMessage("");
        }, 5000);
    
        return;
      }
    
      try {
        const response = await axios.put(
          `https://demo1.we-demo.xyz/partnerstitle/update/${selectedTermId}`,
          updateTerm
        );
    
        if (response.status === 200) {
          console.log("Achievements updated successfully:", response.data);
          fetchData();
          setUpdateTerm({ title: "", paragraph: "" });
          setSelectedTermId(null);
          setSuccessMessage("Achievements updated successfully");
          setUpdateErrorMessage(""); // Clear any existing error message
          setTimeout(() => {
            setSuccessMessage("");
          }, 5000);
        }
      } catch (error) {
        console.error("Error updating Achievements:", error);
        setUpdateErrorMessage("Failed to update Achievements. Please try again.");
      }
    };
    


  useEffect(() => {
    fetchData();
    if (showUpdateForm) {
      // Assuming the first input field has an id 'update-title'
      document.getElementById('update-title').focus();
    }
  }, [showUpdateForm]);











  return (
    <div className="content">
  <div >

  
        
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <CardTitle tag="h4">Achievements Section</CardTitle>
                </CardHeader>
                <CardBody>
                  <Table responsive>
                    <thead className="text-primary">
                      <tr>
                        <th>Title</th>
                        <th>paragraph</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {terms.map((term) => (
                        <tr key={term.id}>
                          <td>{term.title}</td>
                          <td>{term.paragraph}</td>
                          <td>
                            
                            <Button 
                             color="success"
                            onClick={() => handleUpdate(term)}>
                              Update
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      

      {showUpdateForm  && (
    <div className="content mt-5">
                <Form onSubmit={handleTermUpdate}>
            <Row>
              <Col md="6">
                <FormGroup>
                  <label>Title:</label>
                  <Input
                    type="text"
                    name="title"
                    id="update-title"
                    value={updateTerm.title}
                    onChange={(e) =>
                      setUpdateTerm({ ...updateTerm, title: e.target.value })
                    }
                  />
                </FormGroup>
              </Col>
            
              <Col md="9">
                <FormGroup>
                <label for="content" className="textarea-label">paragraph:</label>
               <textarea
                //  style={{ width: '500px' }}
                    type="text"
                    name="paragraph"
                    value={updateTerm.paragraph}
                    onChange={(e) =>
                      setUpdateTerm({ ...updateTerm, paragraph: e.target.value })
                    }
                    style={{ minHeight: '300px', width: '590px'  }}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <div className="update ml-auto mr-auto">
                  <Button className="btn-round" color="primary" type="submit">
                    Update Term
                  </Button>{" "}
      <Button
        className="btn-round"
        color="secondary"
        onClick={() => {
          setShowUpdateForm(false);
          setSelectedTermId(null);
          setUpdateTerm({
            title: "",
            paragraph: "",
          });
        }}
      >
        Cancel
      </Button>
      {successMessage && (
        <div className="alert alert-success" role="alert" style={{ width: '50%', margin: '10px auto', textAlign: 'center'  }}>
          {successMessage}
        </div>
      )}

{updateErrorMessage && (
        <div className="alert alert-danger" role="alert" style={{ width: '50%', margin: '10px auto', textAlign: 'center' }}>
          {updateErrorMessage}
        </div>
      )}

                </div>
              </Col>
            </Row>
          </Form>
        </div>
      )}









<Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Achievements Image</CardTitle>
              </CardHeader>
              <CardBody>
              <Form>
  <Row>
    <Col md="8">
      <FormGroup>
        <label>Images:</label>
        <Input
          type="file"
          name="path" // Changed name to 'path' for consistency, but it's not necessary for functionality in this context
          id="newimageimage"
          onChange={(e) => {
            setNewImage(e.target.files[0]);
          }}
        />
      </FormGroup>
    </Col>
    <Col md="12">
      <Button
        className="btn-round"
        color="primary"
        onClick={addImage} // Ensure addImage function is correctly handling the file upload
      >
        Add Image
      </Button>
      {addSuccessMessage && (
        <div className="alert alert-success" role="alert" style={{ width: '50%', margin: '10px auto', textAlign: 'center' }}>
          {addSuccessMessage}
        </div>
      )}
      {addError && (
        <div className="alert alert-danger" role="alert" style={{ width: '50%', margin: '10px auto', textAlign: 'center' }}>
          {addError}
        </div>
      )}
      {ImageError && (
        <div style={{ color: 'red' }}>
          {ImageError}
        </div>
      )}
    </Col>
  </Row>
</Form>

              

                <CardFooter>
                  <div>Total Image: {imageCount}</div>
                </CardFooter>
                
                <Table responsive>
                  <thead className="text-primary">
                    <tr>
                      <th>Image</th>
                    
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {images.map((images) => (
                      <tr key={images.id}>
                  
                        <td>
                          {images.path && (
                            <img
                              src={`https://demo1.we-demo.xyz/${images.path}`}
                              style={{ maxWidth: "300px", maxHeight: "200px" }}
                            />
                          )}
                        </td>
                        <td>
                          <Button
                            color="success"
                            onClick={() => handleUpdate1(images)}
                          >
                            Update
                          </Button>{" "}
                          <Button
                            color="danger"
                            onClick={() => deleteimage(images.id)}
                          >
                            Delete
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </CardBody>
              {showUpdateImage && editingimage && (
                <CardFooter>
                  <Form>
                    <Row>
                      
                      <Col md="8">
          <FormGroup>
            <label>Current Image:</label>
            {editingimage.path && (
              <div>
                <img
                  src={`https://demo1.we-demo.xyz/uploads/${editingimage.path}`}
                  alt="image"
                  style={{ maxWidth: '200px', marginBottom: '10px' }}
                />
              </div>
            )}
            <label>Update Image (optional):</label>
            <Input
              type="file"
              name="image"
              onChange={(e) => setNewImage(e.target.files[0])}
            />
          </FormGroup>
        </Col>
                      <Col md="12">
                        <Button
                          className="btn-round"
                          color="info"
                          onClick={() => updateimage(editingimage.id)}
                        >
                          Update Image
                        </Button>{" "}
                        <Button
      className="btn-round"
      color="secondary"
      onClick={closeUpdateForm}
    >
      Cancel
    </Button>
                         {updateSuccessMessage && (
                            <div className="alert alert-success" role="alert" style={{ width: '50%', margin: '10px auto', textAlign: 'center'  }}>
                              {updateSuccessMessage}
                            </div>
                          )}
{updateError && (
  <div className="alert alert-danger" role="alert" style={{ width: '50%', margin: '10px auto', textAlign: 'center' }}>
    {updateError}
  </div>
)}
{ImageErrorUpdate && (
  <div style={{ color: 'red' }}>
    {ImageErrorUpdate}
  </div>
)}


                      </Col>
                    </Row>
                  </Form>
                </CardFooter>
              )}
            </Card>
          </Col>
        </Row>
        <Modal isOpen={showDeletePopup} toggle={() => setShowDeletePopup(false)}>
  <ModalHeader toggle={() => setShowDeletePopup(false)}>Confirm Delete</ModalHeader>
  <ModalBody>
    Are you sure you want to delete this image?
  </ModalBody>
  <ModalFooter>
    <Button color="danger" onClick={performDelete}>Delete</Button>
    <Button color="secondary" onClick={() => setShowDeletePopup(false)}>Cancel</Button>
  </ModalFooter>
</Modal>




















    </div>
  );
}

export default Footer;
