import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  CardFooter,
  Row,
  Col,
  Table,
  Form,
  FormGroup,
  Button
} from "reactstrap";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

function Disclaimer() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [updateErrorMessage, setUpdateErrorMessage] = useState("");
  const [disclaimerSections, setDisclaimerSections] = useState([]);
  const [selectedDisclaimerId, setSelectedDisclaimerId] = useState(null);
  const [updateDisclaimer, setUpdateDisclaimer] = useState({
    title: "",
  });
  const [showUpdateDisclaimer, setShowUpdateDisclaimer] = useState(false);
  const [newContent, setNewContent] = useState("");
  const [addError, setAddError] = useState("");
  const [addSuccessMessage, setAddSuccessMessage] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [disclaimerToDelete, setDisclaimerToDelete] = useState(null);
  const [updateSuccessMessage, setUpdateSuccessMessage] = useState("");
  const [updateError, setUpdateError] = useState("");
  const [UpdateRequired, setUpdateRequired] = useState("");
  const [textSections, setTextSections] = useState([]);
  const [editingTextSection, setEditingTextSection] = useState(null);
  const [newText, setNewText] = useState("");

  const confirmDelete = (id) => {
    setDisclaimerToDelete(id);
    setShowDeleteModal(true);
  };

  const performDeleteDisclaimer = async () => {
    try {
      const response = await axios.delete(`https://demo1.we-demo.xyz/disclaimer/delete/${disclaimerToDelete}`);
      if (response.status === 200) {
        setDisclaimerSections(disclaimerSections.filter(disclaimer => disclaimer.id !== disclaimerToDelete));
        setAddSuccessMessage('Disclaimer deleted successfully');
        setTimeout(() => setAddSuccessMessage(''), 5000);
      }
    } catch (error) {
      console.error('Error deleting disclaimer:', error);
      setUpdateErrorMessage('Failed to delete disclaimer. Please try again.');
      setTimeout(() => setUpdateErrorMessage(''), 5000);
    } finally {
      setShowDeleteModal(false);
      setDisclaimerToDelete(null);
    }
  };

  const modules = {
    toolbar: [
      [{ 'color': [] }, { 'background': [] }],
      ['bold', 'italic', 'underline', 'strike'],
      [{ 'header': '1' }, { 'header': '2' }, { 'header': [3, 4, 5, 6] }],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      [{ 'align': [] }],
      ['link', 'image', 'video'],
      ['clean']
    ],
  };

  const formats = [
    'background', 'color', 'bold', 'italic', 'underline', 'strike', 'header',
    'list', 'bullet', 'align', 'link', 'image', 'video'
  ];

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await axios.get("https://demo1.we-demo.xyz/disclaimer/list");
      if (response.data.success) {
        setDisclaimerSections(response.data.data);
      } else {
        console.error("Failed to fetch disclaimers:", response.data.message);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setError("Error fetching data. Please try again later.");
    }
    setLoading(false);
  };

  const fetchTextSections = async () => {
    setLoading(true);
    try {
      const response = await axios.get("https://demo1.we-demo.xyz/disclaimer/list");
      if (response.data.success) {
        setTextSections(response.data.data);
      } else {
        console.error("Failed to fetch text sections:", response.data.message);
      }
    } catch (error) {
      console.error("Error fetching text sections:", error);
      setError("Error fetching text sections. Please try again later.");
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
    fetchTextSections();
  }, []);

  const handleAddDisclaimer = async (e) => {
    e.preventDefault();

    // Make sure newContent is not empty or null
    if (!newContent) {
      setAddError("Content cannot be empty");
      setTimeout(() => setAddError(""), 5000);
      return;
    }

    const payload = { title: newContent };

    try {
      const response = await axios.post('https://demo1.we-demo.xyz/disclaimer/add', payload, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      console.log("Disclaimer added successfully:", response.data);
      setAddSuccessMessage("Disclaimer added successfully");
      fetchData();
      setTimeout(() => setAddSuccessMessage(""), 5000);
      setNewContent("");
    } catch (error) {
      console.error("Error adding disclaimer:", error);
      setAddError("Error adding disclaimer");
      setTimeout(() => setAddError(""), 5000);
    }
  };

  const handleUpdate = (disclaimer) => {
    setSelectedDisclaimerId(disclaimer.id);
    setUpdateDisclaimer({
      title: disclaimer.title,
    });
    setShowUpdateDisclaimer(true);
  };

  const handleEventUpdate = async (e) => {
    e.preventDefault();

    // Create a payload with the updated title
    const payload = { title: updateDisclaimer.title };

    try {
      const response = await axios.put(`https://demo1.we-demo.xyz/disclaimer/update/${selectedDisclaimerId}`, payload, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      console.log("Disclaimer updated successfully:", response.data);
      fetchData();
      setUpdateSuccessMessage("Disclaimer updated successfully");
      setTimeout(() => setUpdateSuccessMessage(""), 5000);
      setShowUpdateDisclaimer(false);
    } catch (error) {
      console.error("Error updating disclaimer:", error);
      setUpdateError("Error updating disclaimer");
      setTimeout(() => setUpdateError(""), 5000);
    }
  };

  const updateTextSection = async (id) => {
    try {
      const response = await axios.put(`https://demo1.we-demo.xyz/disclaimer/update/${id}`, { text: newText });
      if (response.data.success) {
        setUpdateSuccessMessage("Text section updated successfully");
        fetchTextSections();
        setTimeout(() => setUpdateSuccessMessage(""), 5000);
        setEditingTextSection(null);
      } else {
        setUpdateError("Error updating text section");
        setTimeout(() => setUpdateError(""), 5000);
      }
    } catch (error) {
      console.error("Error updating text section:", error);
      setUpdateError("Error updating text section");
      setTimeout(() => setUpdateError(""), 5000);
    }
  };

  const closeUpdateForm = () => {
    setEditingTextSection(null);
    setNewText("");
  };

  return (
    <div className="content">
      <Row>
        <Col md="12">
          <Card>
            <CardHeader>
              <CardTitle tag="h4">Disclaimer Details</CardTitle>
            </CardHeader>
            <CardBody>
            <Table responsive>
  <thead className="text-primary">
    <tr>
      <th>Content</th>
      <th>Actions</th>
    </tr>
  </thead>
  <tbody>
    {disclaimerSections && disclaimerSections.length > 0 && disclaimerSections.map((disclaimer, index) => (
      <tr key={index}>
        {disclaimer.title ? (
          <>
            <td dangerouslySetInnerHTML={{ __html: disclaimer.title }}></td>
            <td>
              <Button color="success" onClick={() => handleUpdate(disclaimer)}>Update</Button>
            </td>
          </>
        ) : null}
      </tr>
    ))}
  </tbody>
</Table>


              {showUpdateDisclaimer && (
                <div className="content">
                  <Form onSubmit={handleEventUpdate}>
                    <Row>
                      <Col md="12">
                        <FormGroup>
                          <label>Content:</label>
                          <div style={{ height: '300px', overflowY: 'auto' }}>
                            <ReactQuill
                              value={updateDisclaimer.title}
                              onChange={(value) => setUpdateDisclaimer({ ...updateDisclaimer, title: value })}
                              modules={modules}
                              formats={formats}
                              style={{ height: '80%', color: 'black' }}
                            />
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12">
                        <div className="update ml-auto mr-auto">
                          <Button className="btn-round" color="primary" type="submit">
                            Update Disclaimer
                          </Button>
                          <Button
                            className="btn-round"
                            color="secondary"
                            onClick={() => {
                              setShowUpdateDisclaimer(false);
                              setSelectedDisclaimerId(null);
                            }}
                          >
                            Cancel
                          </Button>
                          {updateErrorMessage && (
                            <div style={{ color: 'red', marginTop: '10px' }}>
                              {updateErrorMessage}
                            </div>
                          )}
                          {updateError && (
                            <div style={{ color: 'red', marginTop: '10px' }}>
                              {updateError}
                            </div>
                          )}
                          {updateSuccessMessage && (
                            <div className="alert alert-success" role="alert" style={{ width: '50%', margin: '10px auto', textAlign: 'center' }}>
                              {updateSuccessMessage}
                            </div>
                          )}
                          {UpdateRequired && (
                            <div style={{ color: 'red', marginTop: '10px' }}>
                              {UpdateRequired}
                            </div>
                          )}
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </div>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>

    </div>
  );
}

export default Disclaimer;
