import React, { useState, useEffect } from 'react';
import {
  Button,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  CardFooter,
  Form,
  FormGroup,
  Input,
  Row,
  Col,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from 'reactstrap';
import axios from 'axios';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const SliderAssociatedOfficesCMS = () => {
  const [imageAssociatedOffices, setImageAssociatedOffices] = useState([]);
  const [textAssociatedOffices, setTextAssociatedOffices] = useState([]);
  const [newImage, setNewImage] = useState(null);
  const [newText, setNewText] = useState('');
  const [addSuccessMessage, setAddSuccessMessage] = useState('');
  const [addError, setAddError] = useState('');
  const [updateSuccessMessage, setUpdateSuccessMessage] = useState('');
  const [updateError, setUpdateError] = useState('');
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [associatedOfficeToDelete, setAssociatedOfficeToDelete] = useState(null);
  const [editingImageAssociatedOffice, setEditingImageAssociatedOffice] = useState(null);
  const [editingTextAssociatedOffice, setEditingTextAssociatedOffice] = useState(null);
  const [imageErrorUpdate, setImageErrorUpdate] = useState('');

  useEffect(() => {
    fetchAssociatedOfficesImages();
    fetchAssociatedOfficesTexts();
  }, []);

  const fetchAssociatedOfficesImages = async () => {
    try {
      const response = await axios.get('https://demo1.we-demo.xyz/SliderAssociatedOffices/listImages');
      setImageAssociatedOffices(response.data.data);
    } catch (error) {
      console.error('Error fetching associated offices images:', error);
    }
  };

  const fetchAssociatedOfficesTexts = async () => {
    try {
      const response = await axios.get('https://demo1.we-demo.xyz/SliderAssociatedOffices/listTexts');
      setTextAssociatedOffices(response.data.data);
    } catch (error) {
      console.error('Error fetching associated offices texts:', error);
    }
  };

  const addAssociatedOfficeImage = async () => {
    const formData = new FormData();
    formData.append('slider', newImage);

    try {
      await axios.post('https://demo1.we-demo.xyz/SliderAssociatedOffices/addImage', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      setAddSuccessMessage('Associated Office image added successfully!');
      setTimeout(() => {
        setAddSuccessMessage('');
      }, 5000);
      setNewImage(null);
      fetchAssociatedOfficesImages();
    } catch (error) {
      setAddError('Please select an image to upload.');
      console.error('Error adding associated office image:', error);
    }
  };

  const addAssociatedOfficeText = async () => {
    const data = {
      text: newText,
    };

    try {
      await axios.post('https://demo1.we-demo.xyz/SliderAssociatedOffices/addText', data, {
        headers: { 'Content-Type': 'application/json' },
      });
      setAddSuccessMessage('Associated Office text added successfully!');
      setTimeout(() => {
        setAddSuccessMessage('');
      }, 5000);
      setNewText('');
      fetchAssociatedOfficesTexts();
    } catch (error) {
      setAddError('Please enter text to add.');
      console.error('Error adding associated office text:', error);
    }
  };

  const updateAssociatedOfficeImage = async (id) => {
    const formData = new FormData();
    formData.append('slider', newImage);

    try {
      await axios.put(`https://demo1.we-demo.xyz/SliderAssociatedOffices/updateImage/${id}`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      setUpdateSuccessMessage('Associated Office image updated successfully!');
      setEditingImageAssociatedOffice(null);
      setNewImage(null);
      fetchAssociatedOfficesImages();
    } catch (error) {
      setUpdateError('Error updating associated office image.');
      console.error('Error updating associated office image:', error);
    }
  };

  const updateAssociatedOfficeText = async (id) => {
    const data = {
      text: newText,
    };

    try {
      await axios.put(`https://demo1.we-demo.xyz/SliderAssociatedOffices/updateText/${id}`, data, {
        headers: { 'Content-Type': 'application/json' },
      });
      setUpdateSuccessMessage('Associated Office text updated successfully!');
      setEditingTextAssociatedOffice(null);
      setNewText('');
      fetchAssociatedOfficesTexts();
    } catch (error) {
      setUpdateError('Error updating associated office text.');
      console.error('Error updating associated office text:', error);
    }
  };

  const deleteAssociatedOffice = (id) => {
    setAssociatedOfficeToDelete(id);
    setShowDeletePopup(true);
  };

  const performDelete = async () => {
    try {
      await axios.delete(`https://demo1.we-demo.xyz/SliderAssociatedOffices/delete/${associatedOfficeToDelete}`);
      setShowDeletePopup(false);
      fetchAssociatedOfficesImages();
      fetchAssociatedOfficesTexts();
    } catch (error) {
      console.error('Error deleting associated office:', error);
    }
  };

  const closeUpdateForm = () => {
    setEditingImageAssociatedOffice(null);
    setEditingTextAssociatedOffice(null);
    setNewImage(null);
    setNewText('');
  };


  const modules = {
    toolbar: [
      [{ 'color': [] }, { 'background': [] }],
      ['bold', 'italic', 'underline', 'strike'],
      [{ 'header': '1' }, { 'header': '2' }, { 'header': [3, 4, 5, 6] }],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      [{ 'align': [] }],
      ['link', 'image', 'video'],
      ['clean']                                         
    ],
  };
  
  const formats = [
    'background', 'color', 'bold', 'italic', 'underline', 'strike', 'header',
    'list', 'bullet', 'align', 'link', 'image', 'video'
  ];


  return (
    <div className="content">
      <Row>
        <Col md="12">
          <Card>
            <CardHeader>
              <CardTitle tag="h4">Manage Associated Offices Images</CardTitle>
            </CardHeader>
            <CardBody>
              <Form>
                <Row>
                  <Col md="6">
                    <FormGroup>
                      <label>Image</label>
                      <Input
                        type="file"
                        onChange={(e) => setNewImage(e.target.files[0])}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="12">
                    <Button
                      className="btn-round"
                      color="primary"
                      onClick={addAssociatedOfficeImage}
                    >
                      Add Associated Office Image
                    </Button>
                    {addSuccessMessage && (
                      <div className="alert alert-success" role="alert" style={{ width: '50%', margin: '10px auto', textAlign: 'center' }}>
                        {addSuccessMessage}
                      </div>
                    )}
                    {addError && (
                      <div style={{ color: "red", marginTop: "5px" }} role="alert">
                        {addError}
                      </div>
                    )}
                  </Col>
                </Row>
              </Form>
              <Table responsive>
  <thead className="text-primary">
    <tr>
      <th>Image</th>
      <th>Actions</th>
    </tr>
  </thead>
  <tbody>
    {imageAssociatedOffices.map((associatedOffice, index) => (
      <tr key={associatedOffice.id}>
        <td>
          <img
            src={`https://demo1.we-demo.xyz/${associatedOffice.slider}`}
            alt="Associated Office Slider"
            style={{ maxWidth: '300px', maxHeight: '200px' }}
          />
        </td>
        <td>
          <Button
            color="success"
            onClick={() => {
              setEditingImageAssociatedOffice(associatedOffice);
              setNewImage(null);
            }}
          >
            Update Image
          </Button>{" "}
          {index !== 0 && (
            <Button
              color="danger"
              onClick={() => deleteAssociatedOffice(associatedOffice.id)}
            >
              Delete
            </Button>
          )}
        </td>
      </tr>
    ))}
  </tbody>
</Table>

            </CardBody>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col md="12">
          <Card>
            <CardHeader>
              <CardTitle tag="h4">Text Details</CardTitle>
            </CardHeader>
            <CardBody>
              {/* <Form>
                <Row>
                  <Col md="6">
                    <FormGroup>
                      <label>Text</label>
                      <Input
                        type="text"
                        value={newText}
                        onChange={(e) => setNewText(e.target.value)}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="12">
                    <Button
                      className="btn-round"
                      color="primary"
                      onClick={addAssociatedOfficeText}
                    >
                      Add Associated Office Text
                    </Button>
                    {addSuccessMessage && (
                      <div className="alert alert-success" role="alert" style={{ width: '50%', margin: '10px auto', textAlign: 'center' }}>
                        {addSuccessMessage}
                      </div>
                    )}
                    {addError && (
                      <div style={{ color: "red", marginTop: "5px" }} role="alert">
                        {addError}
                      </div>
                    )}
                  </Col>
                </Row>
              </Form> */}
              <Table responsive>
                <thead className="text-primary">
                  <tr>
                    <th>Text</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {textAssociatedOffices.map((associatedOffice) => (
                    <tr key={associatedOffice.id}>
                      <td dangerouslySetInnerHTML={{ __html: associatedOffice.text }}></td>
                      <td>
                        <Button
                          color="success"
                          onClick={() => {
                            setEditingTextAssociatedOffice(associatedOffice);
                            setNewText(associatedOffice.text);
                          }}
                        >
                          Update Text
                        </Button>{" "}
                        {/* <Button
                          color="danger"
                          onClick={() => deleteAssociatedOffice(associatedOffice.id)}
                        >
                          Delete
                        </Button> */}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </Col>
      </Row>

      {(editingImageAssociatedOffice || editingTextAssociatedOffice) && (
        <CardFooter>
          <Form>
            <Row>
              {editingTextAssociatedOffice && (
                <Col md="6">
                  <FormGroup>
                    <label>Text</label>
                    <div
                      style={{
                        height: '300px', // Adjust the height as needed
                        overflowY: 'auto', // Add a vertical scrollbar when content overflows
                      }}
                    >
                      <ReactQuill
                        value={newText}
                        onChange={setNewText}
      modules={modules} 
      formats={formats}
      style={{ height: '80%', color: 'black' }}
      />
                    </div>
                  </FormGroup>
                </Col>
              )}

              {editingImageAssociatedOffice && (
                <Col md="6">
                  <FormGroup>
                    <label>Image</label>
                    <Input
                      type="file"
                      onChange={(e) => setNewImage(e.target.files[0])}
                    />
                  </FormGroup>
                </Col>
              )}
              <Col md="12">
                {editingTextAssociatedOffice && (
                  <Button
                    className="btn-round"
                    color="primary"
                    onClick={() => updateAssociatedOfficeText(editingTextAssociatedOffice.id)}
                  >
                    Update Text
                  </Button>
                )}{" "}
                {editingImageAssociatedOffice && (
                  <Button
                    className="btn-round"
                    color="primary"
                    onClick={() => updateAssociatedOfficeImage(editingImageAssociatedOffice.id)}
                  >
                    Update Image
                  </Button>
                )}{" "}
                <Button
                  className="btn-round"
                  color="secondary"
                  onClick={closeUpdateForm}
                >
                  Cancel
                </Button>
                {updateSuccessMessage && (
                  <div className="alert alert-success" role="alert" style={{ width: '50%', margin: '10px auto', textAlign: 'center' }}>
                    {updateSuccessMessage}
                  </div>
                )}
                {updateError && (
                  <div className="alert alert-danger" role="alert" style={{ width: '50%', margin: '10px auto', textAlign: 'center' }}>
                    {updateError}
                  </div>
                )}
                {imageErrorUpdate && (
                  <div style={{ color: 'red' }}>
                    {imageErrorUpdate}
                  </div>
                )}
              </Col>
            </Row>
          </Form>
        </CardFooter>
      )}

      <Modal isOpen={showDeletePopup} toggle={() => setShowDeletePopup(false)}>
        <ModalHeader toggle={() => setShowDeletePopup(false)}>Delete Associated Office</ModalHeader>
        <ModalBody>
          Are you sure you want to delete this associated office?
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={performDelete}>Delete</Button>
          <Button color="secondary" onClick={() => setShowDeletePopup(false)}>Cancel</Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default SliderAssociatedOfficesCMS;
