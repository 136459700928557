import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Row,
  Col,
  Table,
  Form,
  FormGroup,
  Input,
  Button, Modal, ModalHeader, ModalBody, ModalFooter
} from "reactstrap";

function Footer() {
  const [terms, setTerms] = useState([]);

  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const [selectedTermId, setSelectedTermId] = useState(null);
  const [updateTerm, setUpdateTerm] = useState({
    headtitle: "",
    title: "",
    paragraph: "",
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [termCount, setTermCount] = useState(0);
  const [successMessage, setSuccessMessage] = useState("");
  const [updateErrorMessage, setUpdateErrorMessage] = useState("");
  const [NewsSection, setNewsSection] = useState([]);
  const [UpdateRequired, setUpdateRequired] = useState("");
  const [showDeleteNavbarAboutPopup, setShowDeleteNavbarAboutPopup] = useState(false);
  const [updateSuccessMessage, setUpdateSuccessMessage] = useState("");
  const [updateError, setUpdateError] = useState("");
  const [selectedImageId, setselectedImageId] = useState(null);
  const [showUpdateImage, setshowUpdateImage] = useState(false);
  const [updateImage, setupdateImage] = useState({
    image: "",
    button: "",
    name: "",
    link: "",
    address: "",
    mail: "",
    mobile: "",
    fax: "",
    email: "",
    website: "",
    map: "",
    formtitle: "" // Add formtitle here
  });
  const [ImageError, setImageError] = useState("");
  const [addError, setAddError] = useState("");
  const [addSuccessMessage, setAddSuccessMessage] = useState("");
  const [showImageUpdate, setShowImageUpdate] = useState(false);
  const [newBackgroundImage, setNewBackgroundImage] = useState(null);
  const [backgroundImage, setBackgroundImage] = useState(null);

  const isValidImageExtension = (fileName) => {
    const validExtensions = ['.webp', '.gif', '.png', '.jpg', '.jpeg'];
    const fileExtension = fileName.slice(((fileName.lastIndexOf(".") - 1) >>> 0) + 2).toLowerCase();
    return validExtensions.includes(`.${fileExtension}`);
  };

  const fetchData1 = async () => {
    setLoading(true);
    try {
      // Fetch feature section data
      const response = await axios.get("https://demo1.we-demo.xyz/contactusdata/list");
      if (response.data.success) {
        setNewsSection(response.data.data);
      } else 
        console.error("Failed to fetch Contact Us:", response.data.message);
      
    } catch (error) {
      console.error("Error fetching data:", error);
      setError("Error fetching data. Please try again later.");
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData1(); // Call fetchNews when the component mounts
  }, []);

  const handleUpdate1 = (event) => {
    if (event.id) {
      // For feature sections
      setselectedImageId(event.id);
      setupdateImage({
        title: event.title,
        address: event.address,
        mobile: event.mobile,
        email: event.email,
        fax: event.fax,
        mailtitle: event.mailtitle,
        name: event.name,
        mail: event.mail,
        mailaddress: event.mailaddress,
        image: event.image,
        formtitle: event.formtitle // Add formtitle here
      });
      setshowUpdateImage(true);
    } else {
      // For background image
      setNewBackgroundImage(null);
      setShowImageUpdate(true);
    }
  };

  const handleEventUpdate = async (e) => {
    e.preventDefault();
    const formData = new FormData();
  
    if (selectedImageId) {
      // Update feature section
      formData.append('title', updateImage.title);
      formData.append('address', updateImage.address);
      formData.append('mobile', updateImage.mobile);
      formData.append('email', updateImage.email);
      formData.append('fax', updateImage.fax);
      formData.append('mailtitle', updateImage.mailtitle);
      formData.append('name', updateImage.name);
      formData.append('mail', updateImage.mail);
      formData.append('mailaddress', updateImage.mailaddress);
      formData.append('formtitle', updateImage.formtitle); // Add formtitle here
      if (updateImage.image instanceof File) {
        // Assuming isValidImageExtension is defined and checks the file extension
        if (!isValidImageExtension(updateImage.image.name)) {
          setUpdateError("Invalid file extension. Allowed extensions are .webp, .gif, .png, .jpg, .jpeg");
          setTimeout(() => setUpdateError(""), 5000);
          return;
        }
        formData.append('image', updateImage.image);
      }
  
      try {
        const response = await axios.put(`https://demo1.we-demo.xyz/contactusdata/update/${selectedImageId}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        console.log("Contact Us updated successfully:", response.data);
        fetchData1(); // Assuming fetchData1 fetches the Contact Uss
        setUpdateSuccessMessage("Contact Us updated successfully");
        setTimeout(() => setUpdateSuccessMessage(""), 5000);
        setshowUpdateImage(false);
      } catch (error) {
        console.error("Error updating Contact Us:", error);
        setUpdateError("Error updating Contact Us");
        setTimeout(() => setUpdateError(""), 5000);
      }
    } else {
        // Update background image
        if (newBackgroundImage) {
            if (!isValidImageExtension(newBackgroundImage.name)) {
                setUpdateError("Invalid image file extension for background image. Allowed extensions are .webp, .gif, .png, .jpg, .jpeg");
                setTimeout(() => setUpdateError(""), 5000);
                return;
            }

            formData.append('image', newBackgroundImage);

            try {
                const response = await axios.put(`https://demo1.we-demo.xyz/contactusdata/update/1`, formData, {
                    headers: { 'Content-Type': 'multipart/form-data' },
                });

                // Fetch the updated background image URL from the server
                const updatedBgImageResponse = await axios.get("https://demo1.we-demo.xyz/contactusdata/list");
                if (updatedBgImageResponse.data.success) {
                    setBackgroundImage(updatedBgImageResponse.data.data.path);
                }

                setNewBackgroundImage(null);
                setShowImageUpdate(false);
                setUpdateSuccessMessage("image updated successfully");
                setTimeout(() => setUpdateSuccessMessage(""), 5000);
            } catch (error) {
                console.error("Error updating image:", error);
                setUpdateError("Error updating image");
                setTimeout(() => setUpdateError(""), 5000);
            }
        } else {
            setUpdateError("Invalid image file extension for image");
            setTimeout(() => setUpdateError(""), 5000);
        }
    }
  };

  return (
    <div className="content">
        <div className="content">
  <Row>
    <Col md="12">
      <Card>
        <CardBody>
          <Table responsive>
            <thead className="text-primary">
              <tr>
                <th>Title</th>
                <th>Address</th>
                <th>Mobile</th>
                <th>Email</th>
                <th>Fax</th>
                <th>Mail Title</th>
                <th>Name</th>
                <th>Mail</th>
                <th>Mail Address</th>
                <th>Form Title</th> {/* Add formtitle here */}
                <th>Image</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {NewsSection.map((news, index) => (
                <tr key={index}>
                  <td>{news.title}</td>
                  <td>{news.address}</td>
                  <td>{news.mobile}</td>
                  <td>{news.email}</td>
                  <td>{news.fax}</td>
                  <td>{news.mailtitle}</td>
                  <td>{news.name}</td>
                  <td>{news.mail}</td>
                  <td>{news.mailaddress}</td>
                  <td>{news.formtitle}</td> {/* Add formtitle here */}
                  <td>
                    <img src={`https://demo1.we-demo.xyz/${news.image}`} alt="Icon" style={{ width: '200px' }} />
                  </td>
                  <td>
                    <Button color="success" onClick={() => handleUpdate1(news)}>Update</Button>
                    </td>
                    {' '}
                    
                </tr>
              ))}
            </tbody>
          </Table>
        
          {showUpdateImage && (
  <div className="content">
    <Form onSubmit={handleEventUpdate}>
      <Row>
        <Col md="7">
          <FormGroup>
            <label>Title:</label>
            <Input
              type="text"
              name="title"
              value={updateImage.title}
              onChange={(e) => setupdateImage({ ...updateImage, title: e.target.value })}
            />
          </FormGroup>
        </Col>
        <Col md="7">
          <FormGroup>
            <label>address:</label>
            <Input
              type="text"
              name="address"
              value={updateImage.address}
              id="edit-navbar-name"
              onChange={(e) => setupdateImage({ ...updateImage, address: e.target.value })}
            />
          </FormGroup>
        </Col>

        <Col md="7">
          <FormGroup>
            <label>Mobile:</label>
            <Input
              type="text"
              name="mobile"
              value={updateImage.mobile}
              onChange={(e) => setupdateImage({ ...updateImage, mobile: e.target.value })}
            />
          </FormGroup>
        </Col>
        <Col md="7">
          <FormGroup>
            <label>Email:</label>
            <Input
              type="text"
              name="email"
              value={updateImage.email}
              id="edit-navbar-name"
              onChange={(e) => setupdateImage({ ...updateImage, email: e.target.value })}
            />
          </FormGroup>
        </Col>

        <Col md="7">
          <FormGroup>
            <label>Fax:</label>
            <Input
              type="text"
              name="fax"
              value={updateImage.fax}
              onChange={(e) => setupdateImage({ ...updateImage, fax: e.target.value })}
            />
          </FormGroup>
        </Col>
        <Col md="7">
          <FormGroup>
            <label>Mail Title:</label>
            <Input
              type="text"
              name="mailtitle"
              value={updateImage.mailtitle}
              id="edit-navbar-name"
              onChange={(e) => setupdateImage({ ...updateImage, mailtitle: e.target.value })}
            />
          </FormGroup>
        </Col>

        <Col md="7">
          <FormGroup>
            <label>Name:</label>
            <Input
              type="text"
              name="name"
              value={updateImage.name}
              onChange={(e) => setupdateImage({ ...updateImage, name: e.target.value })}
            />
          </FormGroup>
        </Col>
        <Col md="7">
          <FormGroup>
            <label>Mail:</label>
            <Input
              type="text"
              name="mail"
              value={updateImage.mail}
              id="edit-navbar-name"
              onChange={(e) => setupdateImage({ ...updateImage, mail: e.target.value })}
            />
          </FormGroup>
        </Col>

        <Col md="7">
          <FormGroup>
            <label>Mail Address:</label>
            <Input
              type="text"
              name="mailaddress"
              value={updateImage.mailaddress}
              onChange={(e) => setupdateImage({ ...updateImage, mailaddress: e.target.value })}
            />
          </FormGroup>
        </Col>

        <Col md="7">
          <FormGroup>
            <label>Form Title:</label> {/* Add formtitle here */}
            <Input
              type="text"
              name="formtitle"
              value={updateImage.formtitle}
              onChange={(e) => setupdateImage({ ...updateImage, formtitle: e.target.value })}
            />
          </FormGroup>
        </Col>
        
        <Col md="7">
        <FormGroup>
          {updateImage.image && (
            <div>
              <label>Current Image:</label>
              <div>
                <img src={`https://demo1.we-demo.xyz/${updateImage.image}`} alt="Current Icon" style={{ width: '100px', marginBottom: '10px' }} />
              </div>
            </div>
          )}
          <label className="mt-3">Upload New Image:</label>
          <Input
            type="file"
            name="image"
            onChange={(e) => setupdateImage({ ...updateImage, image: e.target.files[0] })}
          />
        </FormGroup>
      </Col>
      </Row>

      <Row>
        <Col md="12">
          <div className="update ml-auto mr-auto">
            <Button className="btn-round" color="primary" type="submit">
              Update Contact Us
            </Button>{" "}
            <Button
              className="btn-round"
              color="secondary"
              onClick={() => {
                setshowUpdateImage(false);
                setselectedImageId(null);
              }}
            >
              Cancel
            </Button>
            {updateErrorMessage && (
    <div style={{ color: 'red', marginTop: '10px' }}>
        {updateErrorMessage}
    </div>
)}

            {updateError && (
              <div style={{ color: 'red', marginTop: '10px' }}>
                {updateError}
              </div>
            )}
            {updateSuccessMessage && (
              <div className="alert alert-success" role="alert" style={{ width: '50%', margin: '10px auto', textAlign: 'center' }}>
                {updateSuccessMessage}
              </div>
            )}
            {UpdateRequired && (
              <div style={{ color: 'red', marginTop: '10px' }}>
                {UpdateRequired}
              </div>
            )}
          </div>
        </Col>
      </Row>
    </Form>
  </div>
)}

        </CardBody>
      </Card>
    </Col>
  </Row>
</div>
    </div>
  );
}

export default Footer;
