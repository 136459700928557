// Terms.js (Front-End)

import React, { useEffect, useState } from "react";
import ReactQuill, { Quill } from 'react-quill';
import axios from "axios";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Row,
  Col,
  Table,
  Form,
  FormGroup,
  Input,
  Button, Modal, ModalHeader, ModalBody, ModalFooter
} from "reactstrap";

function Footer() {
  const [terms, setTerms] = useState([]);
  const [News, setNews] = useState([]);
  const [NewsSection, setNewsSection] = useState([]);
  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const [selectedTermId, setSelectedTermId] = useState(null);
  const [updateTerm, setUpdateTerm] = useState({
    title: "",
    description: "",
  });
  const [showUpdateFormNews, setShowUpdateFormNews] = useState(false);
  const [selectedTermIdNews, setSelectedTermIdNews] = useState(null);
  
  const [updateTermNews, setUpdateTermNews] = useState({
    title: "",
    description: "",
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [termCount, setTermCount] = useState(0);
  const [successMessage, setSuccessMessage] = useState("");
  const [updateErrorMessage, setUpdateErrorMessage] = useState("");
  const [showImageUpdate, setShowImageUpdate] = useState(false);
  const [selectedImageId, setselectedImageId] = useState(null);
  const [updateImage, setupdateImage] = useState({
    image: "",
    title: "",
    paragraph: "",
  })
  const [showUpdateImage, setshowUpdateImage] = useState(false);
  const [newBackgroundImage, setNewBackgroundImage] = useState(null);
  const [ImageError, setImageError] = useState("");
  const [updateError, setUpdateError] = useState("");
  const [updateSuccessMessage, setUpdateSuccessMessage] = useState("");
  const [backgroundImage, setBackgroundImage] = useState(null);
  const [UpdateRequired, setUpdateRequired] = useState("");
  const [showDeleteNavbarAboutPopup, setShowDeleteNavbarAboutPopup] = useState(false);
  const [addError, setAddError] = useState("");
  const [addSuccessMessage, setAddSuccessMessage] = useState("");

  const [newService, setNewService] = useState({
    title: "",
    paragraph: "",
  });
  

  


  const isValidImageExtension = (fileName) => {
    const validExtensions = ['.webp', '.gif', '.png', '.jpg', '.jpeg', '.tif'];
    const fileExtension = fileName.slice(((fileName.lastIndexOf(".") - 1) >>> 0) + 2).toLowerCase();
    return validExtensions.includes(`.${fileExtension}`);
  };

  

  const fetchData = async () => {
    try {
      const response = await axios.get("https://demo1.we-demo.xyz/LatestPosts/list");
      if (response.data && response.data.success) {
        setTerms(response.data.data); // Wrapping the object in an array
        setTermCount(1); // Since it'sa single object
      } else {
        console.error("Unexpected response format:", response.data);
        // Handle this situation appropriately
      }
    } catch (error) {
      console.error(`Error getting data: ${error}`);
      setLoading(false);
      setError("Error fetching data. Please try again later.");
    }
  };
  
  



  const fetchNews = async () => {
    try {
      const response = await axios.get("https://demo1.we-demo.xyz/latestnews/list");
      if (response.data && response.data.success) {
        setNews(response.data.data); // Wrapping the object in an array
   
      } else {
        console.error("Unexpected response format:", response.data);
        // Handle this situation appropriately
      }
    } catch (error) {
      console.error(`Error getting data: ${error}`);
      setLoading(false);
      setError("Error fetching data. Please try again later.");
    }
  };

  useEffect(() => {
    fetchNews(); // Call fetchNews when the component mounts
  }, []);

  const handleUpdate = (term) => {
    setSelectedTermId(term.id);
    setUpdateTerm({
      title: term.title
    });
    setShowUpdateForm(!showUpdateForm);
    };

    const handleTermUpdate = async (e) => {
      e.preventDefault();
    
      // Validation: Check if title and description are filled
      if (!updateTerm.title.trim()) {
        setUpdateErrorMessage("Title and description are required.");
        setTimeout(() => {
          setUpdateErrorMessage("");
        }, 5000);
    
        return;
      }
    
      try {
        const response = await axios.put(
          `https://demo1.we-demo.xyz/LatestPosts/update/${selectedTermId}`,
          updateTerm
        );
    
        if (response.status === 200) {
          console.log("Term updated successfully:", response.data);
          fetchNews();
          setUpdateTerm({ title: ""});
          setSelectedTermId(null);
          setSuccessMessage("Term updated successfully");
          setUpdateErrorMessage(""); // Clear any existing error message
          setTimeout(() => {
            setSuccessMessage("");
          }, 5000);
        }
      } catch (error) {
        console.error("Error updating term:", error);
        setUpdateErrorMessage("Failed to update term. Please try again.");
      }
    };
    

    const handleUpdateNews = (term) => {
        console.log("Updating News item:", term);
        setSelectedTermIdNews(term.id); // Corrected from selectedTermIdNews(term.id)
        setUpdateTermNews({
          title: term.title
        });
        setShowUpdateFormNews(!showUpdateFormNews);
    };
    
    
        const handleTermUpdateNews = async (e) => {
          e.preventDefault();
        
          // Validation: Check if title and description are filled
          if (!updateTermNews.title.trim()) {
            setUpdateErrorMessage("Title and description are required.");
            setTimeout(() => {
              setUpdateErrorMessage("");
            }, 5000);
        
            return;
          }
        
          try {
            const response = await axios.put(
              `https://demo1.we-demo.xyz/latestnews/update/${selectedTermIdNews}`,
              updateTermNews 
            );
        
            if (response.status === 200) {
              console.log("Term updated successfully:", response.data);
              fetchNews();
              setUpdateTermNews({ title: ""});
              setSelectedTermIdNews(null);
              setSuccessMessage("Term updated successfully");
              setUpdateErrorMessage(""); // Clear any existing error message
              setTimeout(() => {
                setSuccessMessage("");
              }, 5000);
            }
          } catch (error) {
            console.error("Error updating term:", error);
            setUpdateErrorMessage("Failed to update term. Please try again.");
          }
        };


  useEffect(() => {
    fetchData();
    if (showUpdateForm) {
      // Assuming the first input field has an id 'update-title'
      document.getElementById('update-title').focus();
    }
  }, [showUpdateForm]);

  useEffect(() => {
    fetchNews();
    if (showUpdateFormNews) {
      // Assuming the first input field has an id 'update-title'
      document.getElementById('update-title').focus();
    }
  }, [showUpdateFormNews]);














  const fetchData1 = async () => {
    setLoading(true);
    try {
      // Fetch feature section data
      const response = await axios.get("https://demo1.we-demo.xyz/featureSection/list");
      if (response.data.success) {
        setNewsSection(response.data.data);
        // setInfoBarCount(response.data.data.length);
      } else 
        console.error("Failed to fetch feature sections:", response.data.message);
      
    } catch (error) {
      console.error("Error fetching data:", error);
      setError("Error fetching data. Please try again later.");
    }
    setLoading(false);
  };
  

  useEffect(() => {
    fetchData1(); // Call fetchNews when the component mounts
  }, []);
  



  const handleAddService = async () => {
    // Validate inputs
    const title = document.getElementById('newServiceTitle').value;
    const paragraph = newService.paragraph;
    const order = newService.order; // Get the order value
  
    const mainImageInput = document.getElementById('newServiceMainImage');
  
    if (!title.trim()) {
      setAddError('Title is required.');
      setTimeout(() => setAddError(''), 5000);
      return;
    }
  
    if (!paragraph.trim()) {
      setAddError('Paragraph is required.');
      setTimeout(() => setAddError(''), 5000);
      return;
    }
  
    if (!mainImageInput.files[0]) {
      setAddError('Image is required.');
      setTimeout(() => setAddError(''), 5000);
      return;
    } else if (!isValidImageExtension(mainImageInput.files[0].name)) {
      setAddError('Invalid main image file extension. Allowed extensions are .webp, .gif, .png, .jpg, .jpeg, .tif.');
      setTimeout(() => setAddError(''), 5000);
      return;
    }
  
    // If all validations pass
    const formData = new FormData();
    formData.append('title', title);
    formData.append('paragraph', paragraph);
    formData.append('order', order); // Append the order to formData
    formData.append('image', mainImageInput.files[0]);
  
    try {
      const response = await axios.post('https://demo1.we-demo.xyz/featureSection/add', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      if (response.status === 200 || response.status === 201) {
        setAddSuccessMessage('News added successfully');
        setTimeout(() => setAddSuccessMessage(''), 5000);
        fetchData1();
      }
    } catch (error) {
      console.error('Error adding news:', error);
      setAddError('Failed to add news. Please try again.');
      setTimeout(() => setAddError(''), 5000);
    }
  };
  


  

  

  const handleUpdate1 = (event) => {
    if (event.id) {
      // For feature sections
      setselectedImageId(event.id);
      setupdateImage({
        title: event.title,
        paragraph: event.paragraph,
        order: event.order, 
        image: event.image // Prepare to update the icon image
      });
      setshowUpdateImage(true);
    } else {
      // For background image
      setNewBackgroundImage(null);
      setShowImageUpdate(true);
    }
  };

 
  
  


  
  const handleEventUpdate = async (e) => {
    e.preventDefault();
    const formData = new FormData();
  
    if (selectedImageId) {
      // Update feature section
      formData.append('title', updateImage.title);
      formData.append('paragraph', updateImage.paragraph);
      formData.append('order', updateImage.order); 
      if (updateImage.image instanceof File) {
        // Assuming isValidImageExtension is defined and checks the file extension
        if (!isValidImageExtension(updateImage.image.name)) {
          setUpdateError("Invalid file extension. Allowed extensions are .webp, .gif, .png, .jpg, .jpeg, .tif.");
          setTimeout(() => setUpdateError(""), 5000);
          return;
        }
        formData.append('image', updateImage.image);
      }
  
      try {
        const response = await axios.put(`https://demo1.we-demo.xyz/featureSection/update/${selectedImageId}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        console.log("Feature section updated successfully:", response.data);
        fetchData1(); // Assuming fetchData1 fetches the feature sections
        setUpdateSuccessMessage("Feature section updated successfully");
        setTimeout(() => setUpdateSuccessMessage(""), 5000);
        setshowUpdateImage(false);
      } catch (error) {
        console.error("Error updating feature section:", error);
        setUpdateError("Error updating feature section");
        setTimeout(() => setUpdateError(""), 5000);
      }
    } else {
        // Update background image
        if (newBackgroundImage) {
            if (!isValidImageExtension(newBackgroundImage.name)) {
                setUpdateError("Invalid image file extension for background image. Allowed extensions are .webp, .gif, .png, .jpg, .jpeg, .tif.");
                setTimeout(() => setUpdateError(""), 5000);
                return;
            }

            formData.append('image', newBackgroundImage);

            try {
                const response = await axios.put(`https://demo1.we-demo.xyz/featureSection/update/1`, formData, {
                    headers: { 'Content-Type': 'multipart/form-data' },
                });

                // Fetch the updated background image URL from the server
                const updatedBgImageResponse = await axios.get("https://demo1.we-demo.xyz/featureSection/list");
                if (updatedBgImageResponse.data.success) {
                    setBackgroundImage(updatedBgImageResponse.data.data.path);
                }

                setNewBackgroundImage(null);
                setShowImageUpdate(false);
                setUpdateSuccessMessage("Background image updated successfully");
                setTimeout(() => setUpdateSuccessMessage(""), 5000);
            } catch (error) {
                console.error("Error updating background image:", error);
                setUpdateError("Error updating background image");
                setTimeout(() => setUpdateError(""), 5000);
            }
        } else {
            setUpdateError("Invalid image file extension for background image");
            setTimeout(() => setUpdateError(""), 5000);
        }
    }
};




const handleDeleteImage = (id) => {
    setselectedImageId(id);
    setShowDeleteNavbarAboutPopup(true);
  };
  
  const performDeleteNavbarAbout = async () => {
    try {
      const response = await axios.delete(`https://demo1.we-demo.xyz/featureSection/delete/${selectedImageId}`);
      if (response.status === 200) {
        const updatedNavbarAboutItems = NewsSection.filter(item => item.id !== selectedImageId);
        setNewsSection(updatedNavbarAboutItems);
        setShowDeleteNavbarAboutPopup(false);
      } else {
        console.error('Failed to delete navbar item');
      }
    } catch (error) {
      console.error('Error deleting navbar item:', error);
    }
  };


  const modules = {
    toolbar: [
      [{ 'color': [] }, { 'background': [] }],
      ['bold', 'italic', 'underline', 'strike'],
      [{ 'header': '1' }, { 'header': '2' }, { 'header': [3, 4, 5, 6] }],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      [{ 'align': [] }],
      ['link', 'image', 'video'],
      ['clean']                                         
    ],
  };
  
  const formats = [
    'background', 'color', 'bold', 'italic', 'underline', 'strike', 'header',
    'list', 'bullet', 'align', 'link', 'image', 'video'
  ];




  return (
    <div className="content">
  <div > 
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <CardTitle tag="h4">News Section</CardTitle>
                </CardHeader>
                <CardBody>
                  <Table responsive>
                    <thead className="text-primary">
                      <tr>
                        <th>Title</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {terms.map((term) => (
                        <tr key={term.id}>
                          <td>{term.title}</td>
                          
                          <td>
                            
                            <Button 
                             color="success"
                            onClick={() => handleUpdate(term)}>
                              Update
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      

      {showUpdateForm  && (
    <div className="content mt-5">
                <Form onSubmit={handleTermUpdate}>
            <Row>
              <Col md="6">
                <FormGroup>
                  <label>Title:</label>
                  <Input
                    type="text"
                    name="title"
                    id="update-title"
                    value={updateTerm.title}
                    onChange={(e) =>
                      setUpdateTerm({ ...updateTerm, title: e.target.value })
                    }
                  />
                </FormGroup>
              </Col>
            
             
            </Row>
            <Row>
              <Col md="12">
                <div className="update ml-auto mr-auto">
                  <Button className="btn-round" color="primary" type="submit">
                    Update Term
                  </Button>{" "}
      <Button
        className="btn-round"
        color="secondary"
        onClick={() => {
          setShowUpdateForm(false);
          setSelectedTermId(null);
          setUpdateTerm({
            title: "",
            description: "",
          });
        }}
      >
        Cancel
      </Button>
      {successMessage && (
        <div className="alert alert-success" role="alert" style={{ width: '50%', margin: '10px auto', textAlign: 'center'  }}>
          {successMessage}
        </div>
      )}

{updateErrorMessage && (
        <div className="alert alert-danger" role="alert" style={{ width: '50%', margin: '10px auto', textAlign: 'center' }}>
          {updateErrorMessage}
        </div>
      )}

                </div>
              </Col>
            </Row>
          </Form>
        </div>
      )}







<div > 
          <Row>
            <Col md="12">
              <Card>
             
                <CardBody>
                  <Table responsive>
                    <thead className="text-primary">
                      <tr>
                        <th>Title</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {News.map((term) => (
                        <tr key={term.id}>
                          <td>{term.title}</td>
                          <td>
                            
                            <Button 
                             color="success"
                            onClick={() => handleUpdateNews(term)}>
                              Update
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      

        {showUpdateFormNews && (
  <div className="content mt-5">
    <Form onSubmit={handleTermUpdateNews}>
      <Row>
        <Col md="6">
          <FormGroup>
            <label>Title:</label>
            <Input
              type="text"
              name="title"
              id="update-title"
              value={updateTermNews.title}
              onChange={(e) =>
                setUpdateTermNews({ ...updateTermNews, title: e.target.value }) // Corrected from setUpdateTerm
              }
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <div className="update ml-auto mr-auto">
            <Button className="btn-round" color="primary" type="submit">
              Update Term
            </Button>{" "}
            <Button
              className="btn-round"
              color="secondary"
              onClick={() => {
                setShowUpdateFormNews(false);
                setSelectedTermIdNews(null);
                setUpdateTermNews({ title: "" }); // Corrected from setUpdateTerm
              }}
            >
              Cancel
            </Button>
            {successMessage && (
              <div className="alert alert-success" role="alert" style={{ width: '50%', margin: '10px auto', textAlign: 'center' }}>
                {successMessage}
              </div>
            )}
            {updateErrorMessage && (
              <div className="alert alert-danger" role="alert" style={{ width: '50%', margin: '10px auto', textAlign: 'center' }}>
                {updateErrorMessage}
              </div>
            )}
          </div>
        </Col>
      </Row>
    </Form>
  </div>
)}















<div className="content">
  <Row>
    <Col md="12">
     
      <Card>

        <CardBody>

        <Form>
    <Row>
      <Col md="7">
        <FormGroup>
          <label>Title:</label>
          <Input type="text" name="title" id="newServiceTitle" />
        </FormGroup>
      </Col>
      <Col md="7">
  <FormGroup>
    <label>Paragraph:</label>
    <div style={{ height: '300px', overflowY: 'auto' }}>
    <ReactQuill
      value={newService.paragraph}
      onChange={(html) => setNewService({ ...newService, paragraph: html })}
      modules={modules} 
      formats={formats}
      style={{ height: '80%', color: 'black' }}
    />
    </div>
  </FormGroup>
</Col>
<Col md="7">
  <FormGroup>
    <label>Order:</label>
    <Input
      type="number"
      name="order"
      id="newServiceOrder"
      value={newService.order}
      onChange={(e) => setNewService({ ...newService, order: e.target.value })}
    />
  </FormGroup>
</Col>


      <Col md="8">
        <FormGroup>
          <label>Image:</label>
          <Input type="file" name="mainImage" id="newServiceMainImage" />
        </FormGroup>
      </Col>

      <Col md="12">
        <Button className="btn-round" color="primary" onClick={handleAddService}>
          Add Service
        </Button>

                      {addSuccessMessage && (
                        <div className="alert alert-success" role="alert" style={{ width: '50%', margin: '10px auto', textAlign: 'center'  }}>
                          {addSuccessMessage}
                        </div>
                      )}
                                       {addError && (
  <div style={{ color: 'red', marginTop: '10px' }}>
    {addError}
  </div>
)}
{ImageError && (
  <div style={{ color: 'red' }}>
    {ImageError}
  </div>
)}


                    </Col>
                  </Row>
                </Form>


                <Table responsive>
  <thead className="text-primary">
    <tr>
      <th>Image</th>
      <th>Title</th>
      <th>Paragraph</th>
      <th>Order</th>
      <th>Actions</th>
    </tr>
  </thead>
  <tbody>
    {NewsSection.map((news, index) => (
      <tr key={index}>
        <td>
          <img src={`https://demo1.we-demo.xyz/${news.image}`} alt="Icon" style={{ width: '200px' }} />
        </td>
        <td>{news.title}</td>
        <td dangerouslySetInnerHTML={{ __html: news.paragraph }}></td>
        <td>{news.order}</td>
        <td>
          <div className="d-flex">
            <Button   
              color="success"  
              onClick={() => handleUpdate1(news)}
              style={{ marginRight: '10px' }}
            >
              Update
            </Button>
            <Button 
              color="danger" 
              onClick={() => handleDeleteImage(news.id)}
            >
              Delete
            </Button>
          </div>
        </td>
      </tr>
    ))}
  </tbody>
</Table>


        

          {showUpdateImage && (
  <div className="content">
    <Form onSubmit={handleEventUpdate}>
      <Row>
        <Col md="7">
          <FormGroup>
            <label>Title:</label>
            <Input
              type="text"
              name="title"
              value={updateImage.title}
              
              onChange={(e) => setupdateImage({ ...updateImage, title: e.target.value })}
            />
          </FormGroup>
        </Col>
        <Col md="7">
  <FormGroup>
    <label>Paragraph:</label>
    <div style={{ height: '300px', overflowY: 'auto' }}>
    <ReactQuill
      value={updateImage.paragraph}
      onChange={(html) => setupdateImage({ ...updateImage, paragraph: html })}
      modules={modules} 
        formats={formats}
        style={{ height: '80%', color: 'black' }}
    />
    </div>
  </FormGroup>
</Col>
<Col md="7">
          <FormGroup>
            <label>Order:</label> {/* Add Order Input */}
            <Input
              type="number"
              name="order"
              value={updateImage.order}
              onChange={(e) => setupdateImage({ ...updateImage, order: e.target.value })}
            />
          </FormGroup>
        </Col>

        
        <Col md="7">
        <FormGroup>
          {updateImage.image && (
            <div>
              <label>Current Image:</label>
              <div>
                <img src={`https://demo1.we-demo.xyz/${updateImage.image}`} alt="Current Icon" style={{ width: '100px', marginBottom: '10px' }} />
              </div>
            </div>
          )}
          <label className="mt-3">Upload New Image:</label>
          <Input
            type="file"
            name="image"
            onChange={(e) => setupdateImage({ ...updateImage, image: e.target.files[0] })}
          />
        </FormGroup>
      </Col>
            </Row>

      <Row>
        <Col md="12">
          <div className="update ml-auto mr-auto">
            <Button className="btn-round" color="primary" type="submit">
              Update News
            </Button>{" "}
            <Button
              className="btn-round"
              color="secondary"
              onClick={() => {
                setshowUpdateImage(false);
                setselectedImageId(null);
                
              }}
            >
              Cancel
            </Button>
            {updateErrorMessage && (
    <div style={{ color: 'red', marginTop: '10px' }}>
        {updateErrorMessage}
    </div>
)}

            {updateError && (
              <div style={{ color: 'red', marginTop: '10px' }}>
                {updateError}
              </div>
            )}
            {updateSuccessMessage && (
              <div className="alert alert-success" role="alert" style={{ width: '50%', margin: '10px auto', textAlign: 'center' }}>
                {updateSuccessMessage}
              </div>
            )}
            {UpdateRequired && (
              <div style={{ color: 'red', marginTop: '10px' }}>
                {UpdateRequired}
              </div>
            )}
          </div>
        </Col>
      </Row>
    </Form>
  </div>
)}

        </CardBody>
      </Card>
    </Col>
  </Row>
</div>




<Modal isOpen={showDeleteNavbarAboutPopup} toggle={() => setShowDeleteNavbarAboutPopup(false)}>
<ModalHeader toggle={() => setShowDeleteNavbarAboutPopup(false)}>Confirm Delete</ModalHeader>
<ModalBody>
Are you sure you want to delete this navbar item?
</ModalBody>
<ModalFooter>
<Button color="danger" onClick={performDeleteNavbarAbout}>Delete</Button>{' '}
<Button color="secondary" onClick={() => setShowDeleteNavbarAboutPopup(false)}>Cancel</Button>
</ModalFooter>
</Modal>






    </div>
  );
}

export default Footer;
