import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Card,
  CardBody,
  Row,
  Col,
  Table,
  Form,
  FormGroup,
  Input,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";

function FooterManagement() {
  const [footerItems, setFooterItems] = useState([]);
  const [selectedFooterItem, setSelectedFooterItem] = useState(null);
  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const [addForm, setAddForm] = useState({
    name: "",
    link: "",
    position: "",
    image: null,
  });
  
  const [updateForm, setUpdateForm] = useState({
    name: "",
    link: "",
    position: "",
    image: null,
  });

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState("");
  const [addErrorMessage, setAddErrorMessage] = useState("");
  const [updateErrorMessage, setUpdateErrorMessage] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState(null);

  // Fetch all footer items
  const fetchFooterItems = async () => {
    setLoading(true);
    try {
      const response = await axios.get("https://demo1.we-demo.xyz/footer/list");
      if (response.data && response.data.success) {
        setFooterItems(response.data.data);
      } else {
        console.error("Unexpected response format:", response.data);
      }
    } catch (error) {
      console.error(`Error fetching data: ${error}`);
      setError("Error fetching data. Please try again later.");
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchFooterItems();
  }, []);

  // Handle add new footer item
  const handleAddFooterItem = async () => {
    const formData = new FormData();
    formData.append("name", addForm.name);
    formData.append("link", addForm.link);
    formData.append("position", addForm.position);
    if (addForm.image) {
      formData.append("image", addForm.image);
    }
  
    try {
      const response = await axios.post("https://demo1.we-demo.xyz/footer/add", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      if (response.status === 200 || response.status === 201) {
        setSuccessMessage("Footer item added successfully");
        fetchFooterItems(); // Reload footer items
      }
    } catch (error) {
      console.error("Error adding footer item:", error);
      setAddErrorMessage("Failed to add footer item. Please try again.");
    }
  };
  

  const handleAddInputChange = (e) => {
    const { name, value } = e.target;
    setAddForm((prevState) => ({ ...prevState, [name]: value }));
  };
  
  const handleAddFileChange = (e) => {
    const { files } = e.target;
    if (files && files[0]) {
      setAddForm((prevState) => ({ ...prevState, image: files[0] }));
    }
  };
  

  // Handle update footer item
  const handleUpdateFooterItem = async () => {
    if (!selectedFooterItem) return;
  
    const formData = new FormData();
    formData.append("name", updateForm.name);
    formData.append("link", updateForm.link);
    formData.append("position", updateForm.position);
  
    // Only append the image if a new one is selected
    if (updateForm.image instanceof File) {
      formData.append("image", updateForm.image);
    }
  
    try {
      const response = await axios.put(
        `https://demo1.we-demo.xyz/footer/update/${selectedFooterItem.id}`,
        formData,
        { headers: { "Content-Type": "multipart/form-data" } }
      );
      if (response.status === 200) {
        setSuccessMessage("Footer item updated successfully");
        fetchFooterItems(); // Reload footer items
        setShowUpdateForm(false);
      }
    } catch (error) {
      console.error("Error updating footer item:", error);
      setUpdateErrorMessage("Failed to update footer item. Please try again.");
    }
  };
  
  

  // Handle delete footer item
  const handleDeleteFooterItem = async () => {
    try {
      const response = await axios.delete(`https://demo1.we-demo.xyz/footer/delete/${deleteItemId}`);
      if (response.status === 200) {
        setSuccessMessage("Footer item deleted successfully");
        fetchFooterItems(); // Reload footer items
        setShowDeleteModal(false);
      }
    } catch (error) {
      console.error("Error deleting footer item:", error);
    }
  };

  // Open update form
  const handleEditClick = (item) => {
    setSelectedFooterItem(item);
    setUpdateForm({
      name: item.name,
      link: item.link,
      position: item.position,
      image: item.image ? `https://demo1.we-demo.xyz/${item.image}` : null,  // Store the image URL
    });
    setShowUpdateForm(true);
  };
  

  // Handle input change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUpdateForm((prevState) => ({ ...prevState, [name]: value }));
  };

  // Handle file change
  const handleFileChange = (e) => {
    const { files } = e.target;
    if (files && files[0]) {
      setUpdateForm((prevState) => ({ ...prevState, image: files[0] }));
    }
  };

  return (
    <div className="content">
      <Row>
        <Col md="12">
          <Card>
            <CardBody>
            <Form>
  <Row>
    <Col md="4">
      <FormGroup>
        <label>Name:</label>
        <Input type="text" name="name" value={addForm.name} onChange={handleAddInputChange} />
      </FormGroup>
    </Col>
    <Col md="4">
      <FormGroup>
        <label>Link:</label>
        <Input type="text" name="link" value={addForm.link} onChange={handleAddInputChange} />
      </FormGroup>
    </Col>
    <Col md="4">
      <FormGroup>
        <label>Order:</label>
        <Input type="number" name="position" value={addForm.position} onChange={handleAddInputChange} />
      </FormGroup>
    </Col>
    <Col md="4">
      <FormGroup>
        <label>Image:</label>
        <Input type="file" name="image" onChange={handleAddFileChange} />
      </FormGroup>
    </Col>
    <Col md="12">
      <Button color="primary" onClick={handleAddFooterItem}>
        Add Social Media Item
      </Button>
    </Col>
  </Row>
</Form>


              <Table responsive>
                <thead className="text-primary">
                  <tr>
                    <th>Name</th>
                    <th>Link</th>
                    <th>Order</th>
                    <th>Image</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {footerItems.map((item) => (
                    <tr key={item.id}>
                      <td>{item.name}</td>
                      <td>{item.link}</td>
                      <td>{item.position}</td>
                      <td>
                        {item.image && <img src={`https://demo1.we-demo.xyz/${item.image}`} alt="Footer" style={{ width: '50px' }} />}
                      </td>
                      <td>
                        <Button color="success" onClick={() => handleEditClick(item)}>
                          Update
                        </Button>
                        </td>
                        {" "}
                        <td>
                        <Button color="danger" onClick={() => {
                          setDeleteItemId(item.id);
                          setShowDeleteModal(true);
                        }}>
                          Delete
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Modal isOpen={showDeleteModal} toggle={() => setShowDeleteModal(false)}>
        <ModalHeader toggle={() => setShowDeleteModal(false)}>Confirm Delete</ModalHeader>
        <ModalBody>Are you sure you want to delete this Social Media item?</ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={handleDeleteFooterItem}>
            Delete
          </Button>{" "}
          <Button color="secondary" onClick={() => setShowDeleteModal(false)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>

      {showUpdateForm && (
        <div className="content">
            <Form>
              <FormGroup>
                <label>Name:</label>
                <Input type="text" name="name" value={updateForm.name} onChange={handleInputChange} />
              </FormGroup>
              <FormGroup>
                <label>Link:</label>
                <Input type="text" name="link" value={updateForm.link} onChange={handleInputChange} />
              </FormGroup>
              <FormGroup>
                <label>Order:</label>
                <Input type="number" name="position" value={updateForm.position} onChange={handleInputChange} />
              </FormGroup>
              <FormGroup>
                <label>Image:</label>
                <Input type="file" name="image" onChange={handleFileChange} />
              </FormGroup>

              <Button color="primary" onClick={handleUpdateFooterItem}>
              Update
            </Button>{" "}
            <Button color="secondary" onClick={() => setShowUpdateForm(false)}>
              Cancel
            </Button>
            </Form>
            </div>
      )}
    </div>
  );
}

export default FooterManagement;
